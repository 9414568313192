:root {
  --blue-lighter: #e6f6ff;
  --blue-light: #7cc4fa;
  --blue: #03449e;
  --blue-dark: #002159;
  --gray: #d8dfee;
  --dark-gray: #323f4b;
}

body {
  background-color: var(--blue-lighter);
  color: var(--dark-gray);
  font-family: 'Archivo', sans-serif;
}
