
  ._1t9KscOdWacTRJY471r4Yt {
    text-align: center;
    background-color: var(--blue);
    padding: 0.5rem;
    margin-bottom: 2rem;
    color: #fff;
    height: 3rem;
    box-shadow: 0 4px var(--blue-dark);
  }
