
  ._2fJ0dtXS_ZTgF_-4a0etce {
    font-size: 1.5rem;
    line-height: 0;
    text-decoration: none;
    color: #fff;
    background-color: inherit;
    padding: 0.5rem 1.5rem;
    border: none;
    border-radius: 0.5rem;
    display: inline-block;
    outline: none;
  }
