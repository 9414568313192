
  ._2yyl5q7-KFNYROzcCnxB2V {
    display: flex;
    align-items: center;
  }
  ._21gYjBOe8K6TO-KBqsPtqj {
    padding: 1.5rem;
    display: flex;
    justify-content: center;
  }
