
  ._1Pq35h3_4YVm8XETbotGuz {
    position: relative;
    width: 3rem;
    height: 3rem;
  }
  ._1Pq35h3_4YVm8XETbotGuz._2uVoRvOn9m7bdHc7WJpHfT {
    transition: transform 0.125s linear;
    transform: rotate(90deg);
  }
  ._1Pq35h3_4YVm8XETbotGuz > * {
    position: absolute;
    background-color: var(--dark-gray);
  }
  ._1W35k3EW5jY0GN93gMQSFx > * {
    background-color: var(--blue);
  }
  ._1bvqQvQPkcCUxW-AlgXg24 {
    --size: 2rem;
  }
  ._3gG2vmjvRPwd2EVR7hLwQR {
    --size: 1.25rem;
  }
  ._1W35k3EW5jY0GN93gMQSFx > ._3gG2vmjvRPwd2EVR7hLwQR {
      box-shadow: 0 0 6px var(--blue);
    }
  ._1StB4mExerFL7BC0SeuUo0 {
    --half: calc(var(--size) / 2);
    top: calc(50% - var(--half));
    left: calc(50% - var(--half));
    width: var(--size);
    height: var(--size);
    border-radius: 50%;
  }
  ._3kGTZu0SImsTbexPHWp0jL {
    --thickness: 0.5rem;
    --half: calc(var(--thickness) / 2);
  }
  ._736igrAtturbMHVVbW9IT {
    top: 0;
    left: calc(50% - var(--half));
    width: var(--thickness);
    height: calc(50% + var(--half));
    border-bottom-left-radius: var(--thickness);
    border-bottom-right-radius: var(--thickness);
  }
  ._1-V6AVlAKEM3HnDugFxHZV {
    top: calc(50% - var(--half));
    left: calc(50% - var(--half));
    width: calc(50% + var(--half));
    height: var(--thickness);
    border-top-left-radius: var(--thickness);
    border-bottom-left-radius: var(--thickness);
  }
  .l_JBOfkmIOhyMU6OG6uwy {
    top: calc(50% - var(--half));
    left: calc(50% - var(--half));
    width: var(--thickness);
    height: calc(50% + var(--half));
    border-top-left-radius: var(--thickness);
    border-top-right-radius: var(--thickness);
  }
  ._2fcxXJSlDtTeUv4cnyNE9E {
    top: calc(50% - var(--half));
    left: 0;
    width: calc(50% + var(--half));
    height: var(--thickness);
    border-top-right-radius: var(--thickness);
    border-bottom-right-radius: var(--thickness);
  }
