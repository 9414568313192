
  ._1K855ol2MmsAWpPSgI-U4F {
    display: flex;
    justify-content: space-around;
    width: 100%;
    min-width: 10rem;
    text-align: center;
    text-transform: uppercase;
  }
  ._1IPhh12gWCf2ZtPNl629gx {
    color: var(--gray);
  }
  ._2VMSwUKSciDnDb-24aNiK {
    font-size: 1.25rem;
  }
