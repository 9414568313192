
  ._1b8Ib7jayxIpQcUdTFSCkr {
    font-size: 1.5rem;
    line-height: 0;
    color: #fff;
    text-decoration: none;
    padding: 0.5rem 1.5rem;
    border-radius: 0.5rem;
    outline: none;
  }
