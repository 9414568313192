
  ._3O-ttPpKM5ejDeN3hsDi3R {
    display: block;
    border-radius: 1rem;
    padding: 1rem;
    padding-top: 0;
    margin: 0 1rem 1.5rem;
    background-color: #fff;
    box-shadow: 0 4px var(--blue-dark);
  }
  ._3O-ttPpKM5ejDeN3hsDi3R h2 {
    text-align: center;
    margin: 0rem -1rem 1rem;
    padding: 0.5rem;
    text-transform: uppercase;
    background-color: var(--blue);
    color: #fff;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }
  a._3O-ttPpKM5ejDeN3hsDi3R {
    text-decoration: none;
    color: var(--dark-gray);
  }
  ._1Mm3tKSL5z22leIYdi5Awg {
    display: flex;
    justify-content: space-around;
    width: 100%;
  }
  .g1gtEIjC0AgNuhJMGl5WL {
    flex: 1;
    text-align: center;
    /* padding-bottom: 1.5rem; */
  }
  .g1gtEIjC0AgNuhJMGl5WL ._12mGW5T1UBqecJFVfnar13 {
    margin-top: 0;
    opacity: 0.7;
    text-transform: uppercase;
    margin-bottom: 0.5rem;
  }
  .g1gtEIjC0AgNuhJMGl5WL .WL-gI2v09msPHthlZ263j {
    font-size: 1.25rem;
    margin: 0.25rem 0;
  }
